import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register/register.component';
import { ActivateAccountComponent } from './activate-account/activate-account/activate-account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password/forgot-password.component';
import { GetForgotPasswordComponent } from './get-forgot-password/get-forgot-password/get-forgot-password.component';
import { ChangePasswordMailComponent } from './get-change-password-mail/change-password-mail/change-password-mail.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'activate-account/:token',
    component: ActivateAccountComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'get-forgot-password',
    component: GetForgotPasswordComponent,
  },
  {
    path: 'reset-password/:token',
    component: ChangePasswordMailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
