import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/shared/service/api-services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [MessageService, CommonModule]
})
export class ForgotPasswordComponent implements OnInit {

  public authForm: FormGroup;
  public submitted: boolean = false;
  public isLogin: boolean = true;
  public error: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private messageService: MessageService,
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.authForm = this._fb.group({
      email: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required]],
    });
  }


  public forgotPassword(): void {
    this.submitted = true;
    if (this.authForm.invalid) {
      this.showMessage('bc', 'warn', 'Advertencia', 'Debes llenar todos los campos', 'pi-info-circle');
      this.error = true;
      this.submitted = false;
      return;
    }
    const email = this.buildUser();
    this.authService.forgotPassword(email).subscribe(
      (response: any) => {
        localStorage.setItem('currentUser', JSON.stringify(response));
        this.submitted = false;
        this.error = false;
        this.router.navigate(['/auth/get-forgot-password']);
      },
      (error: any) => {
        this.showMessage('bc', 'error', 'Error', error.error.Message, 'pi-info-circle');
        this.submitted = false;
        this.error = false;
      }
    );
  }

  showMessage(key: string, type: string, title: string, content: string, icon: string) {
    this.messageService.add({
      key: key,
      severity: type,
      summary: title,
      detail: content,
      icon: icon
    });
  }

  public get authControls() {
    return this.authForm.controls;
  }

  buildUser() {
    return {
      email: this.authControls['email'].value,
    }
  }


  ngOnInit(): void {
  }
}
