import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SharedModule } from 'src/app/shared/shared.module';
import { RegisterComponent } from './register/register/register.component';
import { ActivateAccountComponent } from './activate-account/activate-account/activate-account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password/forgot-password.component';
import { GetForgotPasswordComponent } from './get-forgot-password/get-forgot-password/get-forgot-password.component';
import { ChangePasswordMailComponent } from './get-change-password-mail/change-password-mail/change-password-mail.component';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ActivateAccountComponent,
    ForgotPasswordComponent,
    GetForgotPasswordComponent,
    ChangePasswordMailComponent,
  ],
  imports: [
    AuthRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    PasswordModule,
    ToastModule,
    SharedModule,
    NgOptimizedImage
  ],
})
export class AuthModule {}
