<div class="dropzone my-5 cursor-pointer" fileDragDrop (filesChangeEmiter)="onFileChange($event)">
    <div class="text-wrapper cursor-pointer">
        <div class="centered cursor-pointer">
            <input accept=".xml,.pdf,.cer" type="file" name="file" id="file" (change)="onFileChange($event)" multiple>
            <br>
            <label for="file"><span class="textLink">{{ filesnames.length === 0 ?
                    'Seleccionar archivo' :
                    'Reemplazar archivo' }}</span> <br><br>
                <span *ngIf="filesnames.length === 0">o arrastra tu archivo aquí</span>
                <span class="uppercase text-base" *ngIf="filesnames.length !== 0">archivos
                    cargados</span>
                <ul>
                    <li class="text-sm" *ngFor="let file of filesnames">{{ file }}</li>
                </ul>
            </label>
        </div>
    </div>
</div>

<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-right" key="br"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>