import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrl: './drag-drop.component.scss',
  providers: [MessageService, ConfirmationService],
})
export class DragDropComponent {

  @Output() dataEvent = new EventEmitter();
  @Input() validFilesTypes: string[] = [];

  constructor(
    private _messageService: MessageService
  ) {

  }

  emitEvent() {
    this.dataEvent.emit(this.files);
  }

  filesnames: any[] = [];
  files: any[] = [];
  alert: { type: string; message: string; } = { type: 'alert', message: '' };

  onFileChange(pFileList: any) {
    const files = Object.keys(pFileList).map((key: any) => pFileList[key]);
    files.forEach((file, index) => {
      if (!this.validFilesTypes.includes(file.type)) {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `El archivo ${file.name} no es valido`,
        });
        return;
      } else {
        this.files.push(file);
        this.filesnames.push(file.name);
      }
    });

    this.emitEvent();

  }

}
