<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-right" key="bc"></p-toast>
<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container px-0 px-lg-5">
      <div class="row d-flex justify-content-center mx-0 mx-lg-5">
        <div class="col-lg-12 bg-white px-2 px-lg-3 justify-content-center shadow rounded-4 py-5">
          <div class="col-12 d-flex justify-content-center pb-3">
            <img ngSrc="../../../../assets/images/logo-brisa.jpg" width="100" height="70" priority="true" alt="Logo"
              alt="logo" />
          </div>
          <div class="col-12">
            @if(isLogin){
            <div class="card-body">
              <div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                <form [formGroup]="authForm" (ngSubmit)="signUp()" novalidate="">
                  <div class="d-flex flex-row flex-wrap justify-content-between col-lg-12">
                    <div class="col-lg-6 p-field p-col-6 p-md-6 bloque mt-4 mb-2">
                      <label for="firstName">Nombre</label>
                      <span class="p-float-label">
                        <input [formControlName]="'firstName'" class="w-100" type="text" id="firstName" pInputText
                          autocomplete="off" [ngClass]="{
                            'ng-invalid ng-dirty':
                              error &&
                              (authForm.controls['firstName'].hasError(
                                'pattern'
                              ) ||
                                authForm.controls['firstName'].hasError(
                                  'required'
                                ))
                          }" />
                      </span>
                    </div>
                    <div class="col-lg-6 p-field p-col-6 p-md-6 bloque mt-4 mb-2">
                      <label for="lastName">Apellido</label>
                      <span class="p-float-label">
                        <input [formControlName]="'lastName'" class="w-100" type="text" id="lastName" pInputText
                          autocomplete="off" [ngClass]="{
                            'ng-invalid ng-dirty':
                              error &&
                              (authForm.controls['lastName'].hasError(
                                'pattern'
                              ) ||
                                authForm.controls['lastName'].hasError(
                                  'required'
                                ))
                          }" />
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row flex-wrap justify-content-between col-lg-12">
                    <div class="col-lg-6  p-field p-col-6 p-md-6 bloque mt-4 mb-2">
                      <label for="email">Correo electrónico</label>
                      <span class="p-float-label">
                        <input [formControlName]="'email'" class="w-100" type="text" id="email" pInputText
                          autocomplete="off" [ngClass]="{
                            'ng-invalid ng-dirty':
                              error &&
                              (authForm.controls['email'].hasError('pattern') ||
                                authForm.controls['email'].hasError('required'))
                          }" />
                      </span>
                    </div>
                    <div class="col-lg-6  p-field p-col-6 p-md-6 bloque mt-4 mb-2">
                      <label for="businessname">Empresa</label>
                      <span class="p-float-label">
                        <input [formControlName]="'businessname'" class="w-100" type="text" id="businessname" pInputText
                          autocomplete="off" [ngClass]="{
                            'ng-invalid ng-dirty':
                              error &&
                              (authForm.controls['businessname'].hasError(
                                'pattern'
                              ) ||
                                authForm.controls['businessname'].hasError(
                                  'required'
                                ))
                          }" />
                      </span>
                    </div>
                  </div>

                  <div class="d-flex flex-row flex-wrap justify-content-between col-lg-12">
                    <div class="col-lg-6 p-field p-col-6 p-md-6 pt-3 mt-4 mb-2">
                      <label for="password">Contraseña</label>
                      <span class="p-float-label w-100">
                        <p-password [ngClass]="{
                            'ng-invalid ng-dirty':
                              error &&
                              authForm.controls['password'].hasError('required')
                          }" [formControlName]="'password'" inputStyleClass="w-100"
                          styleClass="w-100 p-password p-component p-inputwrapper p-input-icon-right" [feedback]="false"
                          [toggleMask]="true"></p-password>
                      </span>
                    </div>
                    <div class="col-lg-6 p-field p-col-6 p-md-6 pt-3 mt-4 mb-2">
                      <label for="confirmPassword">Confirmar Contraseña</label>
                      <span class="p-float-label w-100">
                        <p-password [ngClass]="{
                            'ng-invalid ng-dirty':
                              error &&
                              authForm.controls['confirmPassword'].hasError(
                                'required'
                              )
                          }" [formControlName]="'confirmPassword'" inputStyleClass="w-100"
                          styleClass="w-100 p-password p-component p-inputwrapper p-input-icon-right" [feedback]="false"
                          [toggleMask]="true"></p-password>
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row flex-wrap col-lg-12">
                    <div class="col-lg-6 p-field p-col-6 p-md-6 bloque mt-5 mb-2">
                      <label for="phoneNumber">Numero telefonico</label>
                      <span class="p-float-label">
                        <input [formControlName]="'phoneNumber'" class="w-100" type="text" id="phoneNumber" pInputText
                          autocomplete="off" [ngClass]="{
                            'ng-invalid ng-dirty':
                              error &&
                              (authForm.controls['phoneNumber'].hasError(
                                'pattern'
                              ) ||
                                authForm.controls['phoneNumber'].hasError(
                                  'required'
                                ))
                          }" />
                      </span>
                    </div>
                  </div>

                  <div class="w-100 d-flex justify-content-center">
                    <button [disabled]="submitted" pButton pRipple type="submit"
                      class="p-button-raised mt-3 text-right">
                      @if(!submitted){ <span>Registrarse</span>} @if(submitted){
                      <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>}
                    </button>
                  </div>
                </form>
              </div>
              <div class="text-center mt-4">
                <a class="text-danger pe-auto" style="cursor: pointer" routerLink="/auth/login">¿Ya tienes cuenta?</a>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>