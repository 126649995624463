import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { Router } from '@angular/router';
import { NavService } from '../../service/nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile : boolean;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  user: any;

  constructor(
    public navServices: NavService,
    private router: Router
    ) {
      this.isOpenMobile = false;
      this.user = JSON.parse(localStorage['currentUser']);
    }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }


  ngOnInit(): void {
    
  }

  logout(): void {
    localStorage.removeItem('currentUser');
    this.router.navigateByUrl('/auth/login');
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

}