import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { DragDropComponent } from './components/drag-drop/drag-drop.component';
import { FileDragNDropDirective } from './directives/file-drag-n-drop.directive';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    DragDropComponent,
    FileDragNDropDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    ToastModule,
    HttpClientModule,
    NgOptimizedImage
  ],
  exports: [RouterModule, CommonModule, DragDropComponent],
  providers: [NavService, WINDOW_PROVIDERS],
})
export class SharedModule { }
