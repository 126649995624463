import { Injectable } from '@angular/core';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  isLoggedIn() {
    if( localStorage['currentUser'] ){
      const user = JSON.parse(localStorage['currentUser']); 
      return user && moment().diff(user.expiresIn, 'minutes') > 0;
    }
    return true;
  }


}