import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/shared/service/api-services/auth.service';
import { catchError, map, Subscription, switchMap, throwError } from 'rxjs';

@Component({
  selector: 'app-change-password-mail',
  templateUrl: './change-password-mail.component.html',
  styleUrls: ['./change-password-mail.component.scss'],
  providers: [MessageService],
})
export class ChangePasswordMailComponent implements OnInit {
  public authForm: FormGroup;
  public submitted: boolean = false;
  public error: boolean = false;
  getToken: any = '';
  email: any;
  username: any;

  constructor(
    private _fb: FormBuilder,
    private messageService: MessageService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.authForm = this._fb.group({
      password: ['', Validators.required],
    });

    this.getToken = this.activatedRoute.snapshot.paramMap.get('token');

  }

  public signIn(): void {
    this.submitted = true;
    if (this.authForm.invalid) {
      this.showMessage(
        'bc',
        'warn',
        'Advertencia',
        'Debes llenar todos los campos',
        'pi-info-circle'
      );
      this.error = true;
      this.submitted = false;
      return;
    }

    const body = {
      resetToken: this.getToken,
      password: this.authControls['password'].value,
    };
    this.authService.changepasswordmail(body).subscribe(
      (resolve) => {
        this.submitted = false;
        this.error = false;
        if (resolve.Type === 'success') {
          this.showMessage('bc', 'success', 'Exito', resolve.Message, 'pi-info-circle');
          setTimeout(() => {
            this.router.navigate(['/auth/login']);
          }, 1500)
        } else {
          this.showMessage('bc', 'error', 'Error', resolve.Message, 'pi-info-circle');
        }
        //localStorage.setItem('currentUser', JSON.stringify(resolve));
      }
    );
  }

  showMessage(
    key: string,
    type: string,
    title: string,
    content: string,
    icon: string
  ) {
    this.messageService.add({
      key: key,
      severity: type,
      summary: title,
      detail: content,
      icon: icon,
    });
  }

  public get authControls() {
    return this.authForm.controls;
  }

  ngOnInit(): void { }
}
