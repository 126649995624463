import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/service/api-services/auth.service';
import { MessageService } from 'primeng/api';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [MessageService, CommonModule],
})
export class RegisterComponent implements OnInit {
  public authForm: FormGroup;
  public submitted: boolean = false;
  public isLogin: boolean = true;
  public error: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private messageService: MessageService,
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.authForm = this._fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [
        '',
        [
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          Validators.required,
        ],
      ],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      businessname: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      roles: ['user', Validators.required],
    });
  }

  public resetPassword() {
    this.submitted = true;
    if (this.authControls['email'].value === '') {
      if (this.authForm.invalid) {
        this.showMessage(
          'bc',
          'warn',
          'Advertencia',
          'Debes proporcionarnos tu correo para validarlo',
          'pi-info-circle'
        );
        this.submitted = false;
        this.error = true;
        return;
      }
    }
    const body = { email: this.authControls['email'].value };
    this.authService.resetPassword(body).subscribe((data) => {
      this.showMessage(
        'bc',
        data.Type,
        data.Type === 'success' ? 'Exito' : 'Error',
        data.Message,
        'pi-check-circle'
      );
      this.submitted = false;
      if (data.Type !== 'error') this.isLogin = true;
    });
  }

  public signUp(): void {
    this.submitted = true;
    if (this.authForm.invalid) {
      this.showMessage(
        'bc',
        'warn',
        'Advertencia',
        'Debes llenar todos los campos',
        'pi-info-circle'
      );
      this.error = true;
      this.submitted = false;
      return;
    }
    const register = this.buildUser();
    this.authService.signUp(register).subscribe(
      (response: any) => {
        localStorage.setItem('currentUser', JSON.stringify(response));
        
          this.showMessage(
            'bc',
            'success',
            'Usuario registrado con exito',
            response.Message,
            'pi-check-circle'
          );
          this.submitted = false;
          this.error = false;
      
        setTimeout(() => {
         this.router.navigate(['/login']);
        }, 5000)
      },
      (error: any) => {
        this.showMessage(
          'bc',
          'error',
          'Error',
          error.error.Message,
          'pi-info-circle'
        );
        this.submitted = false;
        this.error = false;
      }
    );
  }

  buildUser() {
    return {
      firstName: this.authControls['firstName'].value,
      lastName: this.authControls['lastName'].value,
      email: this.authControls['email'].value,
      password: this.authControls['password'].value,
      businessname: this.authControls['businessname'].value,
      phoneNumber: this.authControls['phoneNumber'].value,
      roles: this.authControls['roles'].value,
    };
  }

  showMessage(
    key: string,
    type: string,
    title: string,
    content: string,
    icon: string
  ) {
    this.messageService.add({
      key: key,
      severity: type,
      summary: title,
      detail: content,
      icon: icon,
    });
  }

  public get authControls() {
    return this.authForm.controls;
  }

  ngOnInit(): void {}
}
