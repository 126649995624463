<div>
    <div class="page-wrapper bg-white">
        <app-header (rightSidebarEvent)="rightSidebar($event)"></app-header>
        <div class="page-body-wrapper">
            <div class="page-sidebar" [class.open]="navServices.collapseSidebar">
                <app-sidebar></app-sidebar>
            </div>
            <div class="page-body">
                <main>
                    <nav>
                        <div class="pt-3">
                            <div class="container-fluid px-3">
                                <router-outlet #o="outlet"></router-outlet>
                            </div>
                        </div>
                    </nav>
                </main>
            </div>
            <!-- <router-outlet></router-outlet> -->
        </div>
    </div>
    <!-- <div class="btn-light custom-theme" [class.rtl]="layoutClass" (click)="clickRtl(layoutType)">{{layoutType}}</div> -->
</div>