import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-get-forgot-password',
  templateUrl: './get-forgot-password.component.html',
  styleUrls: ['./get-forgot-password.component.scss'],
  providers: [MessageService],
})
export class GetForgotPasswordComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
