<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-right" key="bc"></p-toast>
<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container px-0 px-lg-5">
      <div class="row d-flex justify-content-center mx-0 mx-lg-5">
        <div class="col-12 col-lg-7 bg-white px-2 px-lg-3 justify-content-center shadow rounded-4 py-5">
          <div class="col-12 d-flex justify-content-center pb-3">
            <img ngSrc="../../../../assets/images/logo-brisa.jpg" width="100" height="70" priority="true" alt="Logo" />
          </div>
          <div class="col-12">
            <div class="card-body">
              <div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
                <p class="text-muted">
                  Por favor revisa tu correo, hemos enviado un enlace para
                  recuperar la contraseña.
                </p>
              </div>
              <div class="text-center mt-4">
                <a class="text-danger pe-auto" style="cursor: pointer" routerLink="/auth/login">Iniciar sesión</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>